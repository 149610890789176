import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { WelcomePage } from '~/components/Welcome';

const Welcome: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="Welcome.pageTitle" />
    <WelcomePage {...props} />
  </>
);

export default Welcome;
