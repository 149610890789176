import { Container as MuiContainer, Typography } from '@material-ui/core';
import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';

export const Container = styled(MuiContainer)`
  height: 45vh;
`;

export const PageTitle = styled(Typography).attrs({
  variant: 'h3',
})`
  font-size: 2.25rem;
  text-align: left;
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing(3)}px;

  ${breakpoint.md`
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.4;
  `}
`;

export const Instructions = styled(Typography).attrs({
  variant: 'body1',
  component: 'p',
})`
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(5)}px;
`;
