import React from 'react';
import { PageProps } from 'gatsby';
import Hero from '~/components/Hero/index';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

interface Props extends PageProps {
  location: Location & {
    state: {
      username?: string;
    };
  };
}

export const WelcomePage: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const username = location.state?.username;
  let headerMessage = t('Welcome.pageTitle');

  if (username) {
    headerMessage += `, ${username}!`;
  }

  return (
    <Hero>
      <Styled.Container>
        <Styled.PageTitle>{headerMessage}</Styled.PageTitle>
        <Styled.Instructions>{t('Welcome.instructions')}</Styled.Instructions>
      </Styled.Container>
    </Hero>
  );
};
